<template>
  <el-card>
    <div slot="header">
      <h2>{{this.postsForm.id ? $t('button.edit') : $t('button.add')}}</h2>
    </div>

    <el-form
        ref="addActivityRef"
        :rules="createProductRules"
        :model="postsForm"
        label-width="80px">

      <el-form-item :label="$t('lang')">
        <el-radio v-model="lang" label="zh-CN">中文</el-radio>
        <el-radio v-model="lang" label="en-US">English</el-radio>
      </el-form-item>

      <el-form-item :label="$t('page.console.menu.category')">
        <el-select v-model="postsForm.type" @change="changeType" filterable>
          <el-option
              v-for="item in types"
              :key="item.value"
              :label="item.name"
              :value="item.value">
          </el-option>
        </el-select>
      </el-form-item>

      <el-form-item :label="$t('page.technicalSupport.title')">
        <el-col :span="8" v-if="lang == 'zh-CN'">
          <el-input v-model="postsForm.title" clearable></el-input>
        </el-col>

        <el-col :span="8" v-else>
          <el-input v-model="postsForm.en_title" clearable></el-input>
        </el-col>
      </el-form-item>

      <el-form-item :label="$t('page.product.desc')">
        <el-col :span="12" v-if="lang == 'zh-CN'">
          <el-input type="textarea" :autosize="{ minRows: 4, maxRows: 6}" v-model="postsForm.desc"></el-input>
        </el-col>
        <el-col :span="12" v-else>
          <el-input type="textarea" :autosize="{ minRows: 4, maxRows: 6}" v-model="postsForm.en_desc"></el-input>
        </el-col>
      </el-form-item>

      <el-form-item :label="$t('page.product.images')">
        <el-row class="images-preview" :gutter="20" type="flex" justify="start">
          <el-col :span="4" v-if="postsForm.thumbnail">
            <div class="images-preview-center">
              <img :src="postsForm.thumbnail">
              <!--<el-image-->
              <!--    fit="cover"-->
              <!--    :src="postsForm.thumbnail">-->
              <!--</el-image>-->
              <div class="images-preview-mask">
                <el-button type="danger" icon="el-icon-delete" circle @click="remove()"></el-button>
              </div>
            </div>
          </el-col>

          <el-col :span="4" v-show="!postsForm.thumbnail">
            <div class="images-upload" @click="selectImages('thumbnail')">
              <i class="el-icon-plus"></i>
            </div>
          </el-col>
        </el-row>
      </el-form-item>

      <el-form-item :label="$t('page.media.images')" v-if="postsForm.type == 'enterpriseImages' || postsForm.type == 'activityImages'">
        <el-table :data="postsForm.attrs" border>
          <el-table-column type="index" label="#"></el-table-column>

          <el-table-column label="image">
            <template slot-scope="scope">
              <el-popover
                  placement="top-start"
                  width="200"
                  trigger="hover">
                <el-image :src="scope.row"></el-image>
                <el-input slot="reference" v-model="scope.row" size="small" clearable>
                  <el-button slot="prepend" icon="el-icon-upload" @click="selectImages('attrs', scope.$index)"></el-button>
                </el-input>
              </el-popover>
            </template>
          </el-table-column>

          <el-table-column :label="$t('action')">
            <template slot-scope="scope">
              <el-button type="danger" size="mini" @click="removeImages('attrs', scope.$index)">{{$t('button.del')}}</el-button>
            </template>
          </el-table-column>
        </el-table>

        <el-button type="primary" plain size="mini" icon="el-icon-plus" @click="addAttrs('attrs')">{{$t('button.add')}}</el-button>
      </el-form-item>

      <el-form-item :label="$t('page.media.video_url')" v-else-if="postsForm.type == 'mediaVideo'">
        <el-col :span="12">
          <el-switch
              v-model="postsForm.en_content"
              active-value="1"
              active-text="iframe">
          </el-switch>
          <el-input v-model="postsForm.content" clearable></el-input>
        </el-col>
      </el-form-item>

      <el-form-item :label="$t('page.product.content')" v-else>
        <editor-bar v-show="lang == 'zh-CN'" ref="editor" :content.sync="postsForm.content" :isClear="isClear" @selectImages="selectImages"  @change="change"></editor-bar>
        <editor-bar v-show="lang == 'en-US'" ref="en_editor" :content.sync="postsForm.en_content" :isClear="isClear" @selectImages="selectImages"  @change="changeEn"></editor-bar>
      </el-form-item>

      <el-form-item>
        <el-button type="primary" size="mini" @click="onSave">{{$t('button.save')}}</el-button>
      </el-form-item>
    </el-form>

    <el-dialog :visible.sync="dialogVisible" :title="$t('tips.select')">
      <select-image
          :key="multiple"
          :multiple="multiple"
          :active="false"
          @select="getImages"
          @close="dialogVisible = false">
      </select-image>
    </el-dialog>
  </el-card>
</template>

<script>
import config from '@/config';
import selectImage from '@/components/Images';
import EditorBar from '@/components/Editor'
import {getPosts, savePosts} from '@/api/os/admin';

export default {
  data() {
    return {
      types: config.default.mediaType,
      lang: 'zh-CN',
      dialogVisible: false,
      postsForm: {
        title: '',
        // model: '',
        desc: '',
        attrs: [],
        content: '',
        thumbnail: '',
        type: '',
        en_title: '',
        en_model: '',
        en_desc: '',
        en_content: '',
      },
      isClear: false,

      selectType: 'images',
      multiple: false,
      index: 0,
    }
  },
  components: {
    selectImage,
    EditorBar
  },
  created() {
    if(this.$route.query.id) {
      getPosts({
        id: this.$route.query.id,
      }).then(res => {
        this.postsForm = res.data.data
        this.$refs.editor.setHtml(this.postsForm.content)
        // en
        this.$refs.en_editor.setHtml(this.postsForm.en_content)
      })
    }
  },
  computed: {
    // add
    createProductRules() {

    }
  },
  methods: {
    changeType(type) {
      if(type == 'enterpriseImages' || type == 'activityImages') {
        this.postsForm.content = '';
        this.postsForm.en_content = '';
      }
      if(type == 'mediaVideo') {
        this.postsForm.content = '';
        this.postsForm.en_content = '';
      }
    },
    getImages(data) {
      this.dialogVisible = false;

      if(this.selectType == 'thumbnail') {
        return this.postsForm.thumbnail = data
      }

      if(this.selectType == 'attrs') {
        return this.$set(this.postsForm.attrs, this.index, data)
      }

      let list = '';
      if(data && data.length > 0) {
        data.forEach(e => {
          list += `<img src="${e}">`;
        })
      }

      if(this.lang == 'en-US'){

        return this.$refs.en_editor.insertImages(list);
      }
      if(this.lang == 'zh-CN'){

        return this.$refs.editor.insertImages(list)
      }
    },
    addAttrs(name) {
      this.postsForm[name].push('')
    },
    remove(index) {
      this.postsForm.thumbnail = ''
    },
    change(val) {
      this.postsForm.content = val
    },
    changeEn(val) {
      this.postsForm.en_content = val
    },
    selectImages(type, index) {
      this.dialogVisible = true
      this.multiple = true
      if(type == 'thumbnail' || type == 'attrs') {
        this.multiple = false
      }
      this.index = index
      this.selectType = type
    },

    removeImages(type, index) {
      this.postsForm[type].splice(index, 1)
    },

    onSave() {
      let data = this.postsForm;
      savePosts(data).then(res => {
        this.$message.success(this.$t('tips.success'));
      })
    },
  }
}
</script>

<style lang="less" scoped>
.images-upload{
  background-color: #fbfdff;
  border: 1px dashed #c0ccda;
  border-radius: 6px;
  box-sizing: border-box;
  width: 100%;
  height: 128px;
  cursor: pointer;
  line-height: 126px;
  vertical-align: top;
  text-align: center;
  i{
    font-size: 28px;
    color: #8c939d;
  }
}
.images-preview{
  flex-wrap: wrap;
  flex-direction: row;
  .el-col{
    margin-bottom: 20px;
  }
  .images-preview-center{
    position: relative;
    height: 100%;
    &:hover{
      .images-preview-mask {
        display: block;
      }
    }
    img{
      width: 100%;
      min-height: 100px;
      display: block;
      cursor: pointer;
    }
    .images-preview-mask{
      width: 100%;
      height: 50px;
      line-height: 50px;
      position: absolute;
      bottom: 0;
      background-color: rgba(0,0,0,.6);
      display: none;
      text-align: center;
      .el-button{
      }
    }
  }
}
</style>